import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Grid, } from '@components/atoms'
import { GridItem, Hero } from '@components/molecules'
import { TransitionMask, Footer } from '@components/organisms'
import { FloatingContactForm } from '@components/forms'
import { H2, Paragraph } from '@components/atoms/BlockRenderer'
import { PageProps } from '@types'
import { GridHelperSwitch, getCdnPath, useNavigationMode, useNavigationModeDispatch, } from '@utils'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Helmet } from 'react-helmet'


const LabsPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroImageConditional, 
    heroVideoConditional,
    heroFX,
    gridItems,
    gridSnap,
    inverted,
    shareImage,
    description,
    title,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }

  const { navVisible } = useNavigationMode()
  const dispatch = useNavigationModeDispatch()
  const setNavVisible = (navVisible: boolean) => dispatch({type: 'setNavigationMode', payload: { navVisible }})
  
  const gridRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const scrollerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  useScrollPosition(
    ({ currPos }) => {
      const aboveFooter = currPos.y < ( scrollerRef.current.scrollHeight - (document.getElementById('footer')?.offsetHeight || 100) - window.innerHeight )
      if( aboveFooter !== navVisible) setNavVisible( aboveFooter )
    }, // effect callback
    [navVisible], // dependencies
    gridRef, // position of element
    false, // use window instead of body.getBoundingClientRect
    15, // performance debounce,
    scrollerRef
  )

  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>

      <GridHelperSwitch />

      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroFX }} />
        
      <Scroller id="snappyGrid" ref={scrollerRef} >
        <Marker ref={gridRef} />
        <StyledGrid>
          {gridItems.filter(p => !!p).map((item) => (
            <GridItem key={item._key} {...item} {...{ gridSnap }} />
          ))}
          <GridSnapper />
          <Footer hiddenGap insideGrid />
        </StyledGrid>
      </Scroller>
          
      <TransitionMask {...{ inverted }} />

      <FloatingContactForm
        subject="TWA Labs contact"
        howText="How can TWA Labs help your brand?"
        hiddenInputs={['borough']}
      >
        <StyledH2>Apply Now</StyledH2>  
        <Paragraph>TWA Labs allows us to help the earliest stage companies that aren’t yet ready for a traditional accelerator or in need of a full branding exercise.To participate, you must have a compelling business concept and market fit with a clearly identified creative need. Space is limited and highly selective. To apply, please provide background details about yourself and your business here:</Paragraph>
      </FloatingContactForm>
    </>
  )
}


const Scroller = styled.div`
  width: 100%;
  scroll-snap-type: y mandatory;
  max-height: 100vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Marker = styled.div`
  height: 1px;
  width: 1px;
`

const StyledGrid = styled(props => <Grid {...props} />)`
  width: 100%;
  @media only screen and (min-width: 744px) {
    width: 100%;
  }
  @media only screen and (min-width: 1152px) {
    width: 100%;
  }
`


const GridSnapper = styled.div`
  grid-column: 1 / span 6;
  height: 1px;
  scroll-snap-align: start;
  @media only screen and (min-width: 744px) {
    grid-column: 1 / span 12;
  }
`



const StyledH2 = styled(props => <H2 {...props} />)`
  font-weight: 700;
`


export const query = graphql`
  query LabsTwoPageQuery {
    sanityPage(slug: {current: {eq: "labs"}}) {
      ...pageFields
    }
  }
`

export default LabsPage
